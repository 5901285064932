import {Injectable} from '@angular/core';
import {ConvertTirToJson} from './convert-tir-to-json.service';
import {ProcessPacejkaJson} from './process-pacejka-json.service';
import {ConfirmationDialog} from '../../common/dialogs/confirmation-dialog.service';
import {ConformPacejkaDataToCanopyStructureService} from './conform-pacejka-data-to-canopy-structure.service';
import { ProcessTaMeTireJson } from './process-tametire-json.service';
import { ConformTaMeTireDataToCanopyStructureService } from './conform-tametire-data-to-canopy-structure.service';
import { ProcessTirJsonResult } from './process-tir-json-result';

@Injectable()
export class PerformTirImportFactory {

  constructor(
    private readonly convertTirToJson: ConvertTirToJson,
    private readonly processPacejkaJson: ProcessPacejkaJson,
    private readonly processTaMeTireJson: ProcessTaMeTireJson,
    private readonly conformPacejkaDataToCanopyStructureService: ConformPacejkaDataToCanopyStructureService,
    private readonly conformTaMeTireDataToCanopyStructureService: ConformTaMeTireDataToCanopyStructureService,
    private readonly confirmationDialog: ConfirmationDialog) {
  }

  public create(simVersion: string){
    return new PerformTirImport(
      simVersion,
      this.convertTirToJson,
      this.processPacejkaJson,
      this.processTaMeTireJson,
      this.conformPacejkaDataToCanopyStructureService,
      this.conformTaMeTireDataToCanopyStructureService,
      this.confirmationDialog);
  }
}

export class PerformTirImport {

  constructor(
    private readonly simVersion: string,
    private readonly convertTirToJson: ConvertTirToJson,
    private readonly processPacejkaJson: ProcessPacejkaJson,
    private readonly processTaMeTireJson: ProcessTaMeTireJson,
    private readonly conformTirDataToCanopyStructureService: ConformPacejkaDataToCanopyStructureService,
    private readonly conformTaMeTireDataToCanopyStructureService: ConformTaMeTireDataToCanopyStructureService,
    private readonly confirmationDialog: ConfirmationDialog){
  }

  public async execute(tirContent: string, existingTyre: any, tyreModel: TyreModel): Promise<any> {
    let tirData = this.convertTirToJson.execute(tirContent);

    if(tyreModel == TyreModel.Pacejka){
      let processResult = await this.processPacejkaJson.execute(tirData, this.simVersion);

      if(!this.processResult(processResult)){
        return;
      }

      let existingWithoutPacejka = this.getParametersWithoutPacejka(existingTyre);

      let result = {
        ...existingWithoutPacejka,
        ...tirData
      };

      this.conformTirDataToCanopyStructureService.execute(result, this.simVersion, processResult.pacejkaVersion);

      return result;
    } else {
      let processResult = await this.processTaMeTireJson.execute(tirData, this.simVersion);

      if(!this.processResult(processResult)){
        return;
      }

      let result = {
        ...existingTyre,
        ...tirData
      };

      this.conformTaMeTireDataToCanopyStructureService.execute(result);

      return result;
    }
  }

  private getParametersWithoutPacejka(input: any): any {
    let result = {
      ...input,
    };

    for (let key in result) {
      if (!result.hasOwnProperty(key)) {
        continue;
      }

      if (key.toUpperCase() !== key){
        // If the parameter isn't uppercase then it isn't pacejka.
        continue;
      }

      delete result[key];
    }

    return result;
  }

  private async processResult(processResult: ProcessTirJsonResult) : Promise<boolean>{
    if(!processResult){
      return false;
    }

    if(processResult.warnings && processResult.warnings.length){
      let confirmationDialogResult = await this.confirmationDialog.show(
        'The following warnings occurred during the import. Would you like to continue?',
        'TIR Import',
        'Continue',
        'Cancel',
        processResult.warnings);

      return !!confirmationDialogResult;
    } else {
      return true;
    }
  }
}

export enum TyreModel{
  Pacejka,
  TaMeTire,
  Other
}
