@if (hasConfigPreview) {
  <div #configPreviewContainer id="config-preview-container">
  </div>
}

<div id="create-config-page">
  <div class="container-fluid">
    <div class="card">
      <h1 class="card-header">Create {{configType.titleName}}</h1>

      <div class="card-body">
        @if (configType.singularKey === 'telemetry') {
          <cs-telemetry-help></cs-telemetry-help>
        }

        <form (ngSubmit)="onSubmit()" [formGroup]="form">
          <p>
            <a id="cancel-button" class="btn btn-smart btn-secondary" [routerLink]="isWorksheetPage ? ['../../../'] : ['../']">
              <i class="fa fa-times"></i>Cancel
            </a>
            @if (showSubmitTelemetry) {
              <button id="create-run-telemetry" type="button" class="btn btn-smart btn-primary" [disabled]="isSubmitting" (click)="onSubmitTelemetry()">
                {{submitTelemetryButton.text}}
              </button>
            }
            @if (!showSubmitTelemetry) {
              <button id="create-submit" type="submit" class="btn btn-smart btn-secondary" [disabled]="isSubmitting || !isLoaded">
                {{submitButton.text}}
              </button>
            }
            <span class="inline-button-text">Import:</span>
            <button id="import-default-button" type="button" class="btn btn-smart btn-secondary" [disabled]="!isLoaded" (click)="onImportDefaultClicked()">
              <i class="fa fa-certificate"></i>Default
            </button>
            <button id="import-staged-button" type="button" class="btn btn-smart btn-secondary" [disabled]="!isLoaded || !canImportStaged" (click)="importStaged()">
              <i class="fa fa-arrow-circle-down"></i>Staged
            </button>
            <!-- IMPORTANT: Must contain no surrounding whitespace so we can add '(...)' around button text using :before/:after. No whitespace means the parenthesis hug the content. -->
            <span id="import-local-file-button" class="btn btn-smart btn-secondary btn-file"><i class="fa fa-upload"></i>File<input id="file-load-button-input" type="file" (change)="processFileImportEvent($event)" accept=".json"></span>
            @if (hasConfigPreview) {
              <button id="update-track-preview-button" type="button" class="btn btn-smart btn-primary" (click)="updateConfigPreview()" [disabled]="!isLoaded">
                Preview
              </button>
            }
          </p>

          <cs-error-message [message]="errorMessage || configPreviewErrorMessage"></cs-error-message>

          @if (showDefaultConfigs) {
            <div id="default-configs-list">
              <h3>Select Default:</h3>
              @for (config of defaultConfigs; track config) {
                <button class="btn btn-secondary" type="button" (click)="importDefault(config)" [attr.data-test-id]="cssSanitize(config.name)">{{config.name}}</button>
              }
            </div>
          }

          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="mb-3">
                <label for="name-input" class="title-label form-label">Name</label>
                <input id="name-input" type="text" class="form-control" formControlName="name">
                @if (!name.pristine && name.hasError('required')) {
                  <p class="text-danger">
                    Name is required
                  </p>
                }
                @if (name.hasError('minlength')) {
                  <p class="text-danger">
                    Name is too short.
                  </p>
                }
                @if (name.hasError('maxlength')) {
                  <p class="text-danger">
                    Name is too long.
                  </p>
                }
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="mb-3">
                <label for="notes-input" class="title-label form-label">Notes</label>
                <textarea autosize [minRows]="1" id="notes-input" class="form-control notes-input" formControlName="notes"></textarea>
                @if (notes.hasError('maxlength')) {
                  <p class="text-danger">
                    Notes are too long.
                  </p>
                }
              </div>
            </div>
          </div>

          @if (isLoaded) {
            <div class="row">
              <div class=col-12>
                <label class="title-label form-label">Custom Properties</label>
                <cs-edit-custom-properties [tenantId]="tenantId" [target]="configType.singularKey"
                  [form]="form"
                  [initialProperties]="customProperties"
                [resultProperties]="customProperties"></cs-edit-custom-properties>
              </div>
            </div>
            @if (!editorInstance.canValidate) {
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="unvalidated-input" class="title-label form-label">JSON</label>
                    <textarea id="unvalidated-input" class="form-control unvalidated-input" formControlName="unvalidatedInput"></textarea>
                  </div>
                </div>
              </div>
            }
          }

          @if (!isLoaded) {
            <cs-loader id="config-loader"></cs-loader>
          }

          <div id="json-editor" class="json-editor"></div>
        </form>
      </div>
    </div>
  </div>
</div>
