.loader-container {
  font-size: 0.5em;
}

.loader-centered {
  text-align: center;
}

.loader-content {
  font-size: 2.2em;
  margin-left: 0.5em;
  vertical-align: top;

  &:empty { display: none }
}
