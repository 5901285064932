import {TirData, TirParameters} from './convert-tir-to-json.service';
import {Injectable} from '@angular/core';
import { ProcessTirJsonResult } from './process-tir-json-result';

@Injectable()
export class ProcessTaMeTireJson {

  public async execute(tirData: TirData, simVersion: string): Promise<ProcessTirJsonResult> {
    let warningMessages: string[] = [];

    if(tirData.MODEL) {
      let model = tirData.MODEL as TirParameters;

      if(model.PROPERTY_FILE_FORMAT != 'TAMETIRE'){
        warningMessages.push(`TIR file defines PROPERTY_FILE_FORMAT as ${model.PROPERTY_FILE_FORMAT} not TAMETIRE`);
      }
    } else {
      warningMessages.push(`TIR file defines does not define MODEL.PROPERTY_FILE_FORMAT`);
    }

    if(tirData.PARAMETERS){
      let parameters = tirData.PARAMETERS as TirParameters;
      if(parameters && parameters.Side){
        if((parameters.Side as string).toLowerCase() !== 'right'){
          warningMessages.push(`TIR file should be for right side, but is defined for ${parameters.Side} side.`);
        }
      } else {
        warningMessages.push(`TIR file should be for right side, but the side is not specified in this file.`);
      }
    }

    return new ProcessTirJsonResult(warningMessages);
  }
}
