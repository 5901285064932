import {Component} from '@angular/core';
import {ConfigType, TelemetryConfigType} from '../config-types';
import {SimVersionStub} from '../../../../generated/api-stubs';
import {OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {sortBy} from '../../../common/sort-by';
import {CONFIG_TYPE_DATA_KEY} from '../config-types';
import {FormSubmissionButton} from '../../../common/forms/form-submission-button';
import {FormSubmissionHandler} from '../../../common/forms/form-submission-handler.service';
import {CanopyJson} from '../../../common/canopy-json.service';
import {CanopyValidators} from '../../../common/forms/canopy-validators.service';
import {OnDestroy} from '@angular/core';
import {SimVersionDocumentCache} from '../../sim-version-document-cache.service';
import {StudyStagingArea} from '../../study-staging-area/study-staging-area.service';
import {getFileNameWithoutExtension} from '../../../common/get-file-name-without-extension';
import {CustomProperty} from '../../custom-properties/custom-properties';
import {UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {Timer} from '../../../common/timer.service';
import {VisualizationFactory} from '../../visualizations/visualization-factory.service';
import {ConfigEditorPageBase} from '../config-editor-page-base';
import {ConvertTelemetryConfigToStudy} from '../convert-telemetry-config-to-study.service';
import {SignificantSimVersions} from '../../../common/significant-sim-versions.service';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {RouterDataCache} from '../../../common/router-data-cache';
import {InputCustomProperty, StudyInput} from '../../../worksheets/study-input';
import {JsonEditor} from '../json-config-editor/json-editor.service';
import { UnvalidatedJsonEditorInstance } from '../json-config-editor/unvalidated-json-editor-instance';
import { JsonEditorInstance } from '../json-config-editor/json-editor-instance';
import {IsWorksheetPage} from '../../is-worksheet-page';
import { ConfigImport } from '../config-import.service';
import { AuthenticationService } from '../../../identity/state/authentication.service';
import {cssSanitize} from '../../../common/css-sanitize';
import { take } from 'rxjs';

@Component({
    templateUrl: './create-config.page.html',
    styleUrls: ['./create-config.page.scss'],
    standalone: false
})
export class CreateConfigPage extends ConfigEditorPageBase implements OnInit, OnDestroy {
  public configType: ConfigType;
  public defaultConfigs: DefaultConfig[];
  public simVersion: string;
  public showDefaultConfigs: boolean;
  public customProperties: CustomProperty[] = [];
  public tenantId: string;
  public userId: string;
  public isWorksheetPage: boolean;

  public form: UntypedFormGroup;
  public name: UntypedFormControl = new UntypedFormControl('', [Validators.required, ...CanopyValidators.configNameValidators]);
  public notes: UntypedFormControl = new UntypedFormControl('', [...CanopyValidators.configNotesValidators]);
  public unvalidatedInput: UntypedFormControl = new UntypedFormControl('', []);
  public submitButton = new FormSubmissionButton('Create', 'Creating...');
  public submitTelemetryButton = new FormSubmissionButton('Run Telemetry', 'Submitting...');
  public isLoaded: boolean;

  public editorInstance: JsonEditorInstance;

  public telemetryConfigType = TelemetryConfigType;

  public routeStudyInput: StudyInput;

  constructor(
    isWorksheetPage: IsWorksheetPage,
    route: ActivatedRoute,
    formBuilder: UntypedFormBuilder,
    private readonly authenticationService: AuthenticationService,
    private readonly simVersionStub: SimVersionStub,
    private readonly configImport: ConfigImport,
    private readonly jsonEditor: JsonEditor,
    private readonly json: CanopyJson,
    private readonly router: Router,
    private readonly routerDataCache: RouterDataCache,
    private readonly formSubmissionHandler: FormSubmissionHandler,
    private readonly simVersionDocumentCache: SimVersionDocumentCache,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly convertTelemetryConfigToStudy: ConvertTelemetryConfigToStudy,
    private readonly significantSimVersions: SignificantSimVersions,
    private readonly getSimVersion: GetSimVersion,
    visualizationFactory: VisualizationFactory,
    timer: Timer,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
    super(visualizationFactory, timer, getFriendlyErrorAndLog);

    this.isWorksheetPage = isWorksheetPage.execute(route);
    this.configType = route.snapshot.data[CONFIG_TYPE_DATA_KEY];
    this.routeStudyInput = this.routerDataCache.pop();
    this.form = formBuilder.group({
      name: this.name,
      notes: this.notes,
      unvalidatedInput: this.unvalidatedInput,
    });
  }

  //public routerCanReuse():any {
  //  return false;
  //}

  public ngOnInit() {
    this.load();
  }

  public ngOnDestroy(): any {
    super.ngOnDestroy();
  }

  public cssSanitize(input: string): string {
    return cssSanitize(input);
  }

  public get canRunTelemetry(): boolean {
    try {
      const current= this.editorInstance.getValue();
      return current.channels && current.channels.length;
    } catch{
      return false;
    }
  }

  public async load(){
    try {
      const userData = this.authenticationService.userDataSnapshot;
      this.tenantId = userData.tenant;
      this.userId = userData.sub;

      this.simVersion = await this.getSimVersion.execute();
      let defaultDocumentsResult = await this.simVersionDocumentCache.getFolder(this.configType.pluralKey, this.simVersion);
      this.defaultConfigs = defaultDocumentsResult.documents.map(
        document => new DefaultConfig(document.name, getFileNameWithoutExtension(document.name)));
      this.defaultConfigs.sort(sortBy('name'));

      this.editorInstance = await this.jsonEditor.create(
        this.form,
        'json-editor',
        this.configType.singularKey,
        undefined,
        this.simVersion,
        this.configType.singularKey);

      if(this.shouldImportFromRoute){
        await this.importFromRoute();
      }

      if(this.editorInstance instanceof UnvalidatedJsonEditorInstance) {
        let unvalidatedEditorInstance = this.editorInstance;
        this.unvalidatedInput.setValue(unvalidatedEditorInstance.unvalidatedContent);
        this.unvalidatedInput.valueChanges.subscribe(v => unvalidatedEditorInstance.unvalidatedContent = v);
      }

      this.isLoaded = true;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async processFileImportEvent(event: Event) {
    try {
      if(!this.isLoaded){
        return;
      }
      this.isLoaded = false;

      this.errorMessage = undefined;

      let newConfig = await this.configImport.extractConfigFromEvent(this.tenantId, event, this.configType.singularKey, this.editorInstance.simVersion);

      if(!newConfig){
        return;
      }

      this.editorInstance.changed.pipe(take(1)).subscribe(() => this.isLoaded = true)
      this.editorInstance.setValue(newConfig.config);

      if(newConfig.notes && typeof newConfig.notes === 'string') {
        this.notes.setValue(newConfig.notes, {});
      }

      if(newConfig.properties){
        this.customProperties = newConfig.properties;
      }

      if(newConfig.name && !this.name.value){
        this.name.setValue(newConfig.name, {});
      }

      await this.loadConfigPreviewIfRequired(newConfig.config);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    } finally {
      this.isLoaded = true;
    }
  }

  public onImportDefaultClicked(){
    if(!this.isLoaded){
      return;
    }

    this.errorMessage = undefined;
    this.showDefaultConfigs = !this.showDefaultConfigs;
  }

  public async importDefault(document: DefaultConfig){
    if(!this.isLoaded){
      return;
    }

    this.isLoaded = false;
    try {
      this.errorMessage = undefined;
      this.showDefaultConfigs = false;

      let documentResult = await this.simVersionStub.getDocument(this.simVersion, document.filePath);

      let data = this.json.parse(documentResult.document.content);
      this.editorInstance.changed.pipe(take(1)).subscribe(() => this.isLoaded = true);
      this.editorInstance.setValue(data);
      this.name.setValue(document.name + ' Copy', {});

      await this.loadConfigPreviewIfRequired(data);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get canImportStaged() {
    return !!this.studyStagingArea.getExactInput(this.configType.singularKey);
  }

  public get shouldImportFromRoute(){
    return !!this.routeStudyInput;
  }

  public async importFromRoute(){
    let item = this.routeStudyInput;
    await this.importStudyInput(item);
  }

  public async importStaged(){
    if(!this.isLoaded){
      return;
    }
    this.isLoaded = false;
    try {
      this.errorMessage = undefined;
      let item = await this.studyStagingArea.getInput(this.configType.singularKey, this.editorInstance.simVersion);
      await this.importStudyInput(item);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isLoaded = true;
  }

  public async importStudyInput(item: StudyInput){
    this.editorInstance.setValue(item.data);
    this.name.setValue(item.name + ' Copy', {});
    this.notes.setValue(item.notes, {});

    await this.timer.yield(); // Yield to ensure custom properties control is reloaded.
    if(item.properties){
      this.customProperties = InputCustomProperty.toMutables(item.properties);
    }

    await this.loadConfigPreviewIfRequired(item.data);
  }

  public async onSubmit() {
    let success = await this.formSubmissionHandler.execute(this.submitSave, this.form, this.submitButton, this);

    if (success) {
      this.router.navigate(['/configs', this.configType.pluralKey]);
    }
  }

  public async onSubmitTelemetry() {
    let success = await this.formSubmissionHandler.execute(this.submitTelemetry, this.form, this.submitTelemetryButton, this);

    if (success) {
      this.router.navigate(['/configs', this.configType.pluralKey]);
    }
  }

  public get isSubmitting(): boolean {
    return this.submitButton.isSubmitting
      || this.submitTelemetryButton.isSubmitting;
  }

  private async submitSave() {
    let editorValue = this.editorInstance.getValue();

    await this.configImport.saveConfig(
      this.tenantId,
      {
        name: this.name.value,
        configType: this.configType.singularKey,
        config: editorValue,
        properties: this.customProperties,
        notes: this.notes.value,
        simVersion: this.editorInstance.simVersion
      });
  }

  private async submitTelemetry() {
    let editorValue = this.editorInstance.getValue();

    await this.convertTelemetryConfigToStudy.execute(
      editorValue,
      this.editorInstance.simVersion,
      this.name.value,
      false,
      this.customProperties,
      this.notes.value);
  }

  public get showSubmitTelemetry() {
    return this.configType.singularKey === this.telemetryConfigType.singularKey
      && this.significantSimVersions.isAfterTelemetrySimulation
      && this.canRunTelemetry;
  }
}

export class DefaultConfig {
  constructor(
    public filePath: string,
    public name: string) {
  }
}
