import { ConfigSubTreeRepository } from '../../config-sub-tree-repository.service';
import { DocumentSubType } from '../../../../../generated/api-stubs';
import { Timer } from '../../../../common/timer.service';
import { EncryptedNodeName } from '../editor-constants';
import { ConfigLoaderResult } from '../../config-loader-dialog/config-loader-dialog.service';
import { GetFriendlyErrorAndLog } from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { ConfirmationDialog } from '../../../../common/dialogs/confirmation-dialog.service';
import { getCanopyJsonEditorOptions } from '../json-editor-customizations/get-canopy-json-editor-options';


export class EditorNodeUtilities {
  constructor(
    public readonly simVersion: string,
    public readonly configType: DocumentSubType,
    public readonly self: any,
    public readonly configSubTreeRepository: ConfigSubTreeRepository,
    private readonly timer: Timer,
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public addButton(container: any, button: any, delegate: () => Promise<void>, finallyDelegate: () => void) {
    let options = getCanopyJsonEditorOptions(this.self);

    let containerButtons = options.containerButtonMap.get(container) ?? [];
    containerButtons.push(button);
    options.containerButtonMap.set(container, containerButtons);
    options.observer.observe(container);

    button.addEventListener('click', async (e: Event) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        await delegate();
      } catch (error) {
        await this.handleError(error);
      }

      if (finallyDelegate) {
        finallyDelegate();
      }
    });
  }

  public async handleError(error: Error) {
    const errorMessage = await this.getFriendlyErrorAndLog.execute(error);
    await this.confirmationDialog.show(errorMessage, 'Error', 'OK', 'OK');
  }

  private isParentSchemaOneOfBranch() {
    return !!(
      this.self
      && this.self.parent
      && (this.self.parent.oneOf || this.self.parent.anyOf));
    // && this.self.parent.original_schema && this.self.parent.original_schema.oneOf); // This doesn't work if the original schema is a ref.
  }

  /*
  private getParentSchemaOneOfArray() {
    if(this.isParentSchemaOneOfBranch()){
      return this.self.parent.original_schema.oneOf as any[];
    }

    return undefined;
  }
  */
  public setValueOrParentValue(value: any) {
    if (this.isParentSchemaOneOfBranch()) {
      this.self.parent.setValue(value);
      this.self.parent.onChange(true);
    } else {
      this.self.setValue(value);
      this.self.onChange(true);
    }
  }

  public isEncryptedSchemaNode(node: any) {
    return node.properties
      && node.properties.name
      && node.properties.name.enum
      && node.properties.name.enum.length === 1
      && node.properties.name.enum[0] === EncryptedNodeName;
  }

  public hasEncryptedOneOfBranch() {
    return (this.self.oneOf || this.self.anyOf)
      && this.self.types.length > 1
      && this.self.types.some((v: any) => this.isEncryptedSchemaNode(v));
  }

  public canEncrypt() {
    return this.hasEncryptedOneOfBranch()
      && !this.isEncryptedSchemaNode(this.self.types[this.self.type]);
  }

  public canDecrypt() {
    return this.hasEncryptedOneOfBranch()
      && this.isEncryptedSchemaNode(this.self.types[this.self.type]);
  }

  public get subTreeData(): ConfigLoaderResult {
    return this.self.subTreeData;
  }
  public set subTreeData(value: ConfigLoaderResult) {
    this.self.subTreeData = value;
  }

  public async flashButton(button: Element) {
    button.classList.add('decrypt-failed-button');
    await this.timer.delay(500);
    button.classList.remove('decrypt-failed-button');
  }
}
