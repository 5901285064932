@import "canopy-base";
.list-filter {

  .mb-3 {
    .filter-progress {
      display: inline-block;
      .loader-container {
        top: 0.25rem;
        position: absolute;
        font-size: 0.6rem;
      }
    }
  }

  border: $filter-border;
  background-color: $header-background;
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
  padding: 0.5rem $card-spacer-x;


  #filter-by-name-input {
  }

  .active-filter-property {
    color: $link-color;
  }

  .inactive-filter-property {
  }

  .d-flex {
    margin-bottom: 0;
    button {
      .fa {
        margin: 0;
      }
    }
  }

  .right-side{
    text-align: right;
  }

  .sort-buttons {
    button {
      margin-top: 0.5rem;
      margin-right: 0.25rem;
      .fa {
        margin: 0 0 0 0.25rem;
      }
    }
  }

  .current-sort-mode {
    display: inline;
    margin-left: 0.2rem;
    font-size: 0.8rem;
    color: gray;
  }

  .group-buttons {
    button {
      margin-top: 0.5rem;
      margin-right: 0.25rem;
    }
  }

  .sort-buttons button, .group-buttons button {
    border: 0;
    background-color: transparent;
    &:hover {
      background-color: $card-cap-bg;
    }
  }

  .filter-buttons{
    visibility: collapse;
    display: none;

    button {
      margin-top: 0.5rem;
      margin-right: 0.25rem;
    }
  }
  .active-filter-buttons{
    visibility: visible;
    display: block;
  }

  .toggle-button {
    color: $gray-500;
  }
  .active-toggle-button{
    color: #1e90ff;
  }

  .item-count {
    background: $brand-info;
    color: white;
    display: inline-block;
    border-radius: 0.5rem;
    height: 1rem;
    min-width: 1rem;
    text-align: center;
    font-size: 0.75rem;
    margin: 0.2rem 0 0.2rem 0.5rem;
  }

  .view-custom-properties {
    hr {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
  }

  .filter-builder-header {
    margin-bottom: 0;
  }

  .query-builder-row {
    .query-builder-buttons {
      margin: 0;
      margin-top: 1rem;

      button {
        margin-top: 0;
      }

      a {
        margin-left: 0.5rem;
      }
    }
  }

  .query-renderer-load-progress, .query-builder-load-progress {
    padding-top: 0.5rem;
    display: block;
  }
}
