<div id="admin-page">

  <div class="container-fluid">

    <h1>Admin</h1>
    <cs-error-message [message]="errorMessage"></cs-error-message>
    <p>
      <button class="btn btn-smart" (click)="setDisplayMode(displayModeType.tenants)"
        [ngClass]="{ 'btn-primary': displayMode === displayModeType.tenants, 'btn-secondary': displayMode !== displayModeType.tenants }">
        Tenants
      </button>
      <a id="view-support-sessions-button" class="btn btn-smart btn-secondary" [routerLink]="['/support-sessions']">
        Support Sessions
      </a>
      <button class="btn btn-smart" (click)="setDisplayMode(displayModeType.pools)"
        [ngClass]="{ 'btn-primary': displayMode === displayModeType.pools, 'btn-secondary': displayMode !== displayModeType.pools }">
        Pools
      </button>
      <button class="btn btn-smart" (click)="setDisplayMode(displayModeType.studyStatistics)"
        [ngClass]="{ 'btn-primary': displayMode === displayModeType.studyStatistics, 'btn-secondary': displayMode !== displayModeType.studyStatistics }">
        Study Statistics
      </button>
      <a id="view-sim-version-documents-button" class="btn btn-smart btn-secondary" [routerLink]="['/admin/documents']">
        Sim Version Documents
      </a>
      <button class="btn btn-smart" (click)="setDisplayMode(displayModeType.globalSimVersion)"
        [ngClass]="{ 'btn-primary': displayMode === displayModeType.globalSimVersion, 'btn-secondary': displayMode !== displayModeType.globalSimVersion }">
        Global Sim Version
      </button>
    </p>

    @if (displayMode === displayModeType.tenants) {
      <div class="card">
        <h1 class="card-header">Tenants</h1>
        <div class="card-body">
          @if (!tenants) {
            <cs-loader></cs-loader>
          }
          @if (tenants) {
            <div>
              <p>
                There are
                <strong>{{tenants.length / 2}}</strong> tenants.
              </p>
              <p>
                <a class="btn btn-smart btn-secondary" [routerLink]="['/create-tenant']">New Tenant</a>
              </p>
              <table class="table tenants-table last-item">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Short Name</th>
                    <th>Enabled</th>
                    <th>Creation Date</th>
                    <th>Sim Version</th>
                  </tr>
                </thead>
                <tbody>
                  @for (row of tenants; track row) {
                    <tr>
                      @if (row.rowType === rowType.tenant) {
                        <td>
                          <a [routerLink]="['/tenants', row.tenant.tenantId]">{{row.tenant.name}}</a>
                          <div class="tenant-id tenant-database-id">{{row.tenant.tenantId}}@if (row.tenant.databaseId) {
                            <span> / {{row.tenant.databaseId}}</span>
                          }</div>
                        </td>
                      }
                      @if (row.rowType === rowType.tenant) {
                        <td>
                          {{row.tenant.shortName}}
                          <div class="tenant-region">{{row.tenant.region}}</div>
                        </td>
                      }
                      @if (row.rowType === rowType.tenant) {
                        <td>@if (row.tenant.isEnabled) {
                          <i class="fa fa-check"></i>
                          }@if (!row.tenant.isEnabled) {
                          <i class="fa fa-times"></i>
                        }</td>
                      }
                      @if (row.rowType === rowType.tenant) {
                        <td>{{row.tenant.creationDate}}</td>
                      }
                      @if (row.rowType === rowType.tenant) {
                        <td>{{row.tenant.simVersion}}</td>
                      }
                      @if (row.rowType === rowType.simulationSummary) {
                        <td colspan="5" class="tenant-statistics-column">
                          @if (!row.expanded) {
                            <button class="btn btn-secondary btn-xsm tenant-statistics-button" (click)="expandRow(row)">Stats</button>
                          }
                          @if (row.expanded) {
                            <button class="btn btn-secondary btn-xsm tenant-statistics-button" (click)="hideRow(row)">Hide</button>
                          }
                          @if (row.expanded) {
                            <cs-tenant-simulation-quota-view [tenantId]="row.tenant.tenantId"></cs-tenant-simulation-quota-view>
                          }
                        </td>
                      }
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>
    }
  </div>

  @if (displayMode === displayModeType.pools) {
    <div>
      <div class="container-fluid">
        <cs-pool-status [tenantId]="userData.tenant" [poolType]="'studyMonitor'"></cs-pool-status>
      </div>
    </div>
  }

  @if (displayMode === displayModeType.studyStatistics) {
    <cs-all-tenants-study-statistics></cs-all-tenants-study-statistics>
  }

  @if (displayMode === displayModeType.globalSimVersion) {
    <cs-set-global-sim-version></cs-set-global-sim-version>
  }
</div>
