@import "canopy-base";

.filter-query-builder-group {
  margin-top: 0.5rem;
  //margin-left: 0.5rem;
  margin-right: -1rem;
  padding: 0 1rem 0 1rem;
  border: 0 solid var(--bs-border-color);
  border-left-width: 1px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.01);

  input[type=text] {
    width: 200px;
    display: inline-block;
  }

  select {
    width: 200px;
    display: inline-block;
  }

  .group-operator-select {
    width: 100px;
    font-weight: bold;
  }

  .group-condition {
    margin-top: 0.5rem;
  }

  .group-child, .group-condition {
    position: relative;

    .remove-group-button, .remove-condition-button {
      position: absolute;
      top: 0;
      right: 1rem;
      .fa {
        margin: 0;
      }
    }

    .absorb-group-button {
      position: absolute;
      top: 0;
      right: 4rem;
      .fa {
        margin: 0;
      }
    }
  }
}
