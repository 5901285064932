@import "canopy-base";

.filter-query-builder-condition {

  .condition-operator-select {
    font-family: $font-family-monospace;
    width: 100px;
  }

  .condition-source-select {
    width: 160px;
  }

  .input-group-area {
    position: relative;
    display: inline-block;
  }

  .input-area {
    position: relative;
    display: inline-block;

    &.hidden-input {
      display: none;
    }

    .errors-area {
      position: absolute;
      bottom: 0rem;
      right: 0.2rem;
      background-color: transparent;
      font-size: 0.7rem;
      pointer-events: none;
      z-index: 10;

      p {
        margin: 0;
      }
    }
  }

  .relative-area {
    position: relative;
    display: inline-block;
  }

}
