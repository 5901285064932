import { Injectable } from '@angular/core';
import { AllButtonCategories } from '../json-editor-node-extensions/editor-node-extensions';
import { EditorNodeExtensionsFactory } from '../json-editor-node-extensions/editor-node-extensions-factory';
import { getCanopyJsonEditorOptions } from './get-canopy-json-editor-options';
import { JsonEditorCustomization } from './json-editor-customization';
import{ JSONEditor } from'@json-editor/json-editor'

@Injectable()
export class ObjectEditor extends JsonEditorCustomization {

  constructor(
    private readonly editorNodeExtensionsFactory: EditorNodeExtensionsFactory) {
    super();
  }

  public apply(): void {
    const service = this;

    JSONEditor.defaults.editors.object = class extends JSONEditor.defaults.editors.object {
      preBuild() {
        // We add explicit property ordering otherwise
        // some of the properties get jumbled up.
        if (this.schema.properties) {
          let propertyNumber = 1;
          for (let key of Object.keys(this.schema.properties)) {
            let schema = this.schema.properties[key];
            if (!schema.propertyOrder) {
              schema.propertyOrder = propertyNumber;
            }

            ++propertyNumber;
          }
        }

        super.preBuild();
      }

      build() {
        super.build();
        const canopyOptions = getCanopyJsonEditorOptions(this);

        this.theme.addHelpLink(this.container, this.schema, this);

        if (this.addproperty_holder) {
          // This prevents the property dialog being hidden when a checkbox is
          // clicked due to the event propagating to the body element.
          this.addproperty_holder.addEventListener('click', function(e: Event) {
            e.stopPropagation();
          });
        }

        if (this.title) {
          let subTreeControls = this.theme.getHeaderButtonHolder();
          subTreeControls.classList.add('hover-button-group');
          this.theme.addHoverArea(this);

          if(this.addproperty_button){
            this.title.appendChild(this.addproperty_button.parentElement);
          }

          this.title.appendChild(subTreeControls);

          this.extensions = service.editorNodeExtensionsFactory.create(canopyOptions.simVersion, canopyOptions.configType, this, canopyOptions.configSubTreeRepository);
          this.extensions.addButtons(subTreeControls, AllButtonCategories);
        }

        if(this.addproperty_button){
          this.addproperty_button.children[1].innerHTML = '&nbsp;Properties';
        }
      }

      setValue(...args: any[]) {
        super.setValue(...args);
        if (this.extensions) {
          this.extensions.update();
        }
      }

      showValidationErrors (errors: any) {
        super.showValidationErrors(errors);
        if(this.error_holder && this.jsoneditor.options.show_errors === 'interaction'){
          this.error_holder.style.display = 'none'
        }
      }
    }
  }
}
