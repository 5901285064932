import { Injectable } from '@angular/core';

@Injectable()
export class ConformTaMeTireDataToCanopyStructureService {

  public execute(data: any): void {
    this.addDefaults(data);
    this.moveCData(data);
    this.moveINIT(data);
  }

  private addDefaults(data: any){
    data.rGripFactor = 1.0;
    if(!data.thermalConditions){
      data.thermalConditions = {};
    }
    if(!data.thermalConditions.solverOptions){
      data.thermalConditions.solverOptions = {};
    }
    if(!data.thermalConditions.solverOptions.thermalTreatment){
      data.thermalConditions.solverOptions.thermalTreatment = {};
    }
    data.thermalConditions.solverOptions.thermalTreatment.name = 'Dynamic';
    data.thermalConditions.solverOptions.thermalTreatment.boundaryConditionType = 'Periodic';
  }

  private moveCData(data: any){
    if(data.DATA){
      if(data.DATA[0]){
        if(data.DATA[0][0]){
          data.DATA = { CData: data.DATA[0][0] };
        }
      }
    }
  }

  private moveINIT(data: any){
    if(data.INIT){
      data.INITIAL_CONDITIONS = data.INIT;
      if(!data.thermalConditions){
        data.thermalConditions = {};
      }
      data.thermalConditions.quasiStaticTemperatures = data.INIT;
      delete data.INIT;
    }
  }
}
