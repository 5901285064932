import { Injectable } from '@angular/core';
import { simVersionToNumber } from '../../visualizations/sim-version-to-number';
import { MF61 } from './process-pacejka-json.service';

const OPERATING_CONDITIONS = 'OPERATING_CONDITIONS';
const INFLPRES = 'INFLPRES';
const pInflation = 'pInflation';
const NOMPRES = 'NOMPRES';
const VERTICAL = 'VERTICAL';
const MODEL = 'MODEL';
const STRUCTURAL = 'STRUCTURAL';
const DIMENSION = 'DIMENSION';
const UNLOADED_RADIUS = 'UNLOADED_RADIUS';
const rUnloaded = 'rUnloaded';
const TRANSIENT_COEFFICIENTS = 'TRANSIENT_COEFFICIENTS';
const SCALING_COEFFICIENTS = 'SCALING_COEFFICIENTS';
const ROLLING_COEFFICIENTS = 'ROLLING_COEFFICIENTS';

interface ParameterMap {
  readonly from: ReadonlyArray<string>;
  readonly to: string;
}

@Injectable()
export class ConformPacejkaDataToCanopyStructureService {

  public execute(data: any, simVersion: string, pacejkaVersion: string): void {
    let simVersionAsNumber = simVersionToNumber(simVersion);

    // Order here is important.
    this.moveUNLOADED_RADIUS(data, simVersionAsNumber);
    this.movePacejkaRadiusEquationParameters(data, simVersionAsNumber);
    this.movePacejkaRollingResistanceParameters(data, simVersionAsNumber);
    this.moveINFLPRES(data, simVersionAsNumber);

    if (pacejkaVersion === MF61) {
      this.moveNOMPRES(data, simVersionAsNumber);
      this.movePacejkaSTRUCTURALToTRANSIENT_COEFFICIENTS(data, simVersionAsNumber);
    }
  }

  private movePacejkaSTRUCTURALToTRANSIENT_COEFFICIENTS(data: any, simVersionAsNumber: number) {
    // We store STRUCTURAL as TRANSIENT_COEFFICIENTS in Pacejka61, so we'll move the parameter to where we expect it to be
    // rather than where it should be.
    if (data[STRUCTURAL]) {
      if (!data[TRANSIENT_COEFFICIENTS]) {
        data[TRANSIENT_COEFFICIENTS] = {};
      }

      data[TRANSIENT_COEFFICIENTS]['CX0'] = data[STRUCTURAL]['LONGITUDINAL_STIFFNESS'];
      data[TRANSIENT_COEFFICIENTS]['CY0'] = data[STRUCTURAL]['LATERAL_STIFFNESS'];
      data[TRANSIENT_COEFFICIENTS]['PCFX1'] = data[STRUCTURAL]['PCFX1'];
      data[TRANSIENT_COEFFICIENTS]['PCFX2'] = data[STRUCTURAL]['PCFX2'];
      data[TRANSIENT_COEFFICIENTS]['PCFX3'] = data[STRUCTURAL]['PCFX3'];
      data[TRANSIENT_COEFFICIENTS]['PCFY1'] = data[STRUCTURAL]['PCFY1'];
      data[TRANSIENT_COEFFICIENTS]['PCFY3'] = data[STRUCTURAL]['PCFY3'];
      data[TRANSIENT_COEFFICIENTS]['PCFY2'] = data[STRUCTURAL]['PCFY2'];
      delete data[STRUCTURAL];
    }
  }

  private moveUNLOADED_RADIUS(data: any, simVersionAsNumber: number) {
    // We store UNLOADED_RADIUS in rUnloaded, so we'll move the parameter to where we expect it to be
    // rather than where it should be.
    if (data[DIMENSION] && data[DIMENSION][UNLOADED_RADIUS]  !== undefined) {
      data[rUnloaded] = data[DIMENSION][UNLOADED_RADIUS];
      delete data[DIMENSION][UNLOADED_RADIUS];
    }
  }

  private moveINFLPRES(data: any, simVersionAsNumber: number) {
    // We store INFLPRES in the incorrect location, so we'll move the parameter to where we expect it to be
    // rather than where it should be.
    if (data[OPERATING_CONDITIONS] && data[OPERATING_CONDITIONS][INFLPRES]  !== undefined) {
      data[pInflation] = data[OPERATING_CONDITIONS][INFLPRES];
      delete data[OPERATING_CONDITIONS][INFLPRES];
    }
  }

  private moveNOMPRES(data: any, simVersionAsNumber: number) {
    // We store NOMPRES in the incorrect location, so we'll move the parameter to where we expect it to be
    // rather than where it should be.
    if (data[OPERATING_CONDITIONS] && data[OPERATING_CONDITIONS][NOMPRES]  !== undefined) {
      if (!data[VERTICAL]) {
        data[VERTICAL] = {};
      }

      data[VERTICAL][NOMPRES] = data[OPERATING_CONDITIONS][NOMPRES];
      delete data[OPERATING_CONDITIONS][NOMPRES];
    }
  }

  private movePacejkaRadiusEquationParameters(data: any, simVersionAsNumber: number) {
    let previousRadiusEquations = data.radiusEquations;
    data.radiusEquations = { name: 'Adapted Pacejka' };

    let missingCount = 0;
    missingCount += this.movePacejkaLoadedRadiusParameters(data, simVersionAsNumber);
    missingCount += this.movePacejkaRollingRadiusParameters(data, simVersionAsNumber);

    if (missingCount > 0) {
      if (previousRadiusEquations === undefined) {
        delete data.radiusEquations;
      } else {
        data.radiusEquations = previousRadiusEquations;
      }
    }
  }

  private movePacejkaLoadedRadiusParameters(data: any, simVersionAsNumber: number) {

    let maps: ParameterMap[] = [
      { from: [MODEL, 'LONGVL'], to: 'V0' },
      { from: [VERTICAL, 'FNOMIN'], to: 'Fz0' },
      { from: [VERTICAL, 'Q_V2'], to: 'qv2' },
      { from: [VERTICAL, 'Q_FCX'], to: 'qfcx1' },
      { from: [VERTICAL, 'Q_FCY'], to: 'qfcy1' },
      { from: [VERTICAL, 'Q_FZ1'], to: 'qfz1' },
      { from: [VERTICAL, 'Q_FZ2'], to: 'qfz2' },
      { from: [VERTICAL, 'Q_RE0'], to: 'qre0' },
      { from: [VERTICAL, 'PFZ1'], to: 'pfz1' },
      { from: [OPERATING_CONDITIONS, NOMPRES], to: 'pi0' },
    ];

    let target = {};
    let missingCount = this.moveParameters(maps, data, target);
    if (missingCount === 0) {
      data.radiusEquations.loadedRadius = target;
    }

    return missingCount;
  }

  private movePacejkaRollingRadiusParameters(data: any, simVersionAsNumber: number): number {

    let maps: ParameterMap[] = [
      { from: [VERTICAL, 'Q_V1'], to: 'qv1' },
      { from: [VERTICAL, 'BREFF'], to: 'BReff' },
      { from: [VERTICAL, 'DREFF'], to: 'DReff' },
      { from: [VERTICAL, 'FREFF'], to: 'FReff' },
    ];

    let target = {};
    let missingCount = this.moveParameters(maps, data, target);
    if (missingCount === 0) {
      data.radiusEquations.rollingRadius = target;
    }

    return missingCount;
  }

  private movePacejkaRollingResistanceParameters(data: any, simVersionAsNumber: number) {

    let maps: ParameterMap[] = [
      { from: [SCALING_COEFFICIENTS, 'LMY'], to: 'LMY' },
      { from: [ROLLING_COEFFICIENTS, 'QSY1'], to: 'QSY1' },
      { from: [ROLLING_COEFFICIENTS, 'QSY2'], to: 'QSY2' },
      { from: [ROLLING_COEFFICIENTS, 'QSY3'], to: 'QSY3' },
      { from: [ROLLING_COEFFICIENTS, 'QSY4'], to: 'QSY4' },
      { from: [ROLLING_COEFFICIENTS, 'QSY5'], to: 'QSY5' },
      { from: [ROLLING_COEFFICIENTS, 'QSY6'], to: 'QSY6' },
      { from: [ROLLING_COEFFICIENTS, 'QSY7'], to: 'QSY7' },
      { from: [ROLLING_COEFFICIENTS, 'QSY8'], to: 'QSY8' },
      { from: [MODEL, 'LONGVL'], to: 'VRef' },
      { from: [VERTICAL, 'FNOMIN'], to: 'Fz0' },
    ];

    let target = { name: 'Pacejka equation' };
    let missingCount = this.moveParameters(maps, data, target);
    if (missingCount === 0) {
      data.rollingResistance = target;
    }

    return missingCount;
  }

  private moveParameters(maps: ReadonlyArray<ParameterMap>, root: any, target: any): number {
    let missingCount = 0;
    for (let item of maps) {
      let value = this.getValue(root, item.from);

      if (value === undefined) {
        ++missingCount;
      } else {
        target[item.to] = value;
      }
    }

    return missingCount;
  }

  private getValue(root: any, path: ReadonlyArray<string>): any {
    let result = root;
    for (let item of path) {

      if (result === undefined) {
        break;
      }

      result = result[item];
    }

    return result;
  }
}
