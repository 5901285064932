import {TirData, TirParameters} from './convert-tir-to-json.service';
import {Injectable} from '@angular/core';
import {simVersionToNumber} from '../../visualizations/sim-version-to-number';
import { ConfirmationDialog } from '../../common/dialogs/confirmation-dialog.service';
import { ProcessTirJsonResult } from './process-tir-json-result';

export const SIDE_DEFINITION = 'sideDefinition';
export const SIM_VERSION_BEFORE_TYRE_SIDE: number = 3523;

export const MF52 = 'MF52';
export const MF61 = 'MF61';
export const MF62 = 'MF62';

@Injectable()
export class ProcessPacejkaJson {
  constructor(private readonly confirmationDialog: ConfirmationDialog) {

  }
  public async execute(tirData: TirData, simVersion: string): Promise<ProcessPacejkaJsonResult> {
    let simVersionAsNumber = simVersionToNumber(simVersion);

    let warningMessages: string[] = [];
    let pacejkaVersion: string | undefined = undefined;

    if(tirData.MODEL) {
      let model = tirData.MODEL as TirParameters;

      if (simVersionAsNumber < SIM_VERSION_BEFORE_TYRE_SIDE) {
        if(model && model.TYRESIDE){
          if((model.TYRESIDE as string).toLowerCase() !== 'left'){
            warningMessages.push(`TIR file should be for LEFT side, but is defined for ${model.TYRESIDE} side.`);
          }
        } else {
          warningMessages.push(`TIR file should be for LEFT side, but the side is not specified in this file.`);
        }
      } else {
        if(model && model.TYRESIDE){
          let side = (model.TYRESIDE as string).toLowerCase();
          if(side.startsWith('l')){
            tirData[SIDE_DEFINITION] = 'Left';
          } else if(side.startsWith('r')) {
            tirData[SIDE_DEFINITION] = 'Right';
          } else {
            let side = await this.confirmationDialog.show(
              'Do these Pacejka coefficients define the behaviour of a left or right tyre?',
              'TIR file side',
              ['Left', 'Right'],
              'Cancel');

            if (!side) {
              return undefined;
            }

            tirData[SIDE_DEFINITION] = side;
          }
        }
      }

      if (model && (model.PROPERTY_FILE_FORMAT || model.FITTYP)) {
        if (model.PROPERTY_FILE_FORMAT === 'MF52' || model.FITTYP === 52) {
          pacejkaVersion = MF52;
        } else if (model.PROPERTY_FILE_FORMAT === 'MF61' || model.FITTYP === 61) {
          pacejkaVersion = MF61;
        } else if (model.PROPERTY_FILE_FORMAT === 'MF62' || model.FITTYP === 62) {
          pacejkaVersion = MF62;
        }

        if (pacejkaVersion === undefined){
          warningMessages.push(`TIR file should be for Pacejka 5.2, 6.1 or 6.2, but the version defined in the file was ${model.PROPERTY_FILE_FORMAT || model.FITTYP}.`);
        }
      } else {
        warningMessages.push(`TIR file should be for Pacejka 5.2, 6.1 or 6.2, but the version was not detected in this file.`);
      }

      const scalingCoefficients = tirData.SCALING_COEFFICIENTS as TirParameters;
      if(scalingCoefficients){
        // Correct common confusion between LFZ0 and LFZO.
        const LFZO = 'LFZO';
        const LFZ0 = 'LFZ0';
        let from = LFZO;
        let to = LFZ0;
        if(simVersionAsNumber < 2632){
          from = LFZ0;
          to = LFZO;
        }

        if(scalingCoefficients[from]){
          scalingCoefficients[to] = scalingCoefficients[from];
          delete scalingCoefficients[from];
        }
      }
    }

    return new ProcessPacejkaJsonResult(warningMessages, pacejkaVersion);
  }
}

export class ProcessPacejkaJsonResult extends ProcessTirJsonResult {
  constructor(
    warnings: ReadonlyArray<string>,
    public readonly pacejkaVersion: string | undefined) {
      super(warnings);
  }
}
