@import "canopy-base";

.filter-query-renderer {
  margin: 0.5rem 0;
  font-family: $font-family-monospace;
  font-size: 0.9rem;
  border: 1px solid var(--bs-border-color);
  border-radius: 3px;
  padding: 0.25rem 0.5rem;
  text-align: center;

  &.query-invalid {
    background: rgb(255, 200, 200);
  }

  &.query-not-applied {
    opacity: 0.5;
  }

  .query-operator {
    color: cornflowerblue;
  }

  .query-value {
    color: #3c763d;
  }

  .query-condition {
    white-space: nowrap;
  }
}
