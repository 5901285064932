@import "canopy-base";

.rendered-markdown {
  img {
    max-width: 80%;
    display: block;
    margin: 2rem auto;
    border: 0 solid gray;
  }

  pre {
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 4px 8px;
    font-family: $font-family-monospace;
    font-size: 0.8rem;
    background: $shade-color-1;
  }
  code {
    font-size: 0.8rem;
  }

  h1 {
    margin-top: 2rem;
    font-weight: bold;
  }

  h2 {
    margin-top: 2rem;
    font-weight: bold;
  }
}
